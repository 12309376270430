import React from 'react';
import { Route , Routes } from 'react-router-dom';
import {Home , About , Contact , Project, Work } from './pages'
import './App.css';

import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

function App() {

  const containerRef = React.useRef(null)

  return (
    <>
    {/* holds all pages - yet to find a better way to do it */}
    {/* <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/about" element={<About />} />
       <Route path="/connect" element={<Contact/>} />
       <Route path="/work" element={<Work/>} />
       <Route path="/project" element={<Project />} />
    </Routes> */}

    <LocomotiveScrollProvider
        options={
          {
            smooth: true,
            // ... all available Locomotive Scroll instance options 
          }
        }
        watch={
          [
            //..all the dependencies you want to watch to update the scroll.
            //  Basicaly, you would want to watch page/location changes
            //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
          ]
        }
        containerRef={containerRef}
      >
        <main data-scroll-container ref={containerRef}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/connect" element={<Contact/>} />
            <Route path="/work" element={<Work/>} />
            <Route path="/project" element={<Project />} />
          </Routes>
        </main>
    </LocomotiveScrollProvider>

    </>
  );
}

export default App;
