import React from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../assets'
import { Navbar } from '../components'
import { Hero } from '../styles/hero'

const home = () => {
  return (
    <div data-scroll-section>
      <Navbar logo= {Logo} />
      {/* hero section */}
    <div className="hero-cont  bg-namiri-dark">
    <Hero className="p-4 container ">
        <div className="md:flex items-end justify-center">
          <div className="lg:w-2/5 md:w-3/5 text-white p-3">
            <h1 className=" font-Gallient font-boxld  text-4xl name">Okanda Steven.</h1>
            <p className=" font-NeueRegular md:w-4/5 ">
              
             A front-end software engineer with a passion for building beautiful and functional web applications , a UX/Ui designer and a creative problem solver. <br /> <br /> Building websites and growing business whilst creating timesless brand at <a href="http://namiricreatives.com/" className='underline'>Namiri Creatives. </a> 
            </p>
          </div>

          <div className="w-3/5 px-5 text-white">
            {/* huge ass menus */}
            <h1 className='menu font-Gallient hover:underline hover:italic '>
              <Link to='/work'>
              Work.
              </Link>
            </h1>
            <h1 className='menu font-Gallient hover:underline hover:italic '>
              <Link to='/about'>
              About.
              </Link>
            </h1>
            <h1 className='menu text-grey font-Gallient hover:underline hover:italic '>
              <Link to='/connect'>
              Connect.
              </Link>
            </h1>
          </div>
        </div>
      </Hero>
    </div>
    </div>
  )
}

export default home