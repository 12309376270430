import styled from "styled-components";

export const Cont = styled.section`
    
    height:95vh ;
    overflow-y: scroll;
    /* width: 100%; */

    /* Hide scrollbar for webkit-based browsers */
        ::-webkit-scrollbar {
        width: 0.5em; /* adjust the width if needed */
        display: none;
        }

        ::-webkit-scrollbar-track {
        background: #f1f1f1; /* set the color of the track */
        display: none;
        }

        ::-webkit-scrollbar-thumb {
        background: #888; /* set the color of the scrollbar thumb */
        }

        /* Hide scrollbar for non-webkit-based browsers */
        overflow: -moz-scrollbars-none; /* for Firefox */
        -ms-overflow-style: none; /* for Internet Explorer and Edge */
        scrollbar-width: none; /* for modern browsers */


    @media (max-width: 480px){
        height:100% ;
    }

    @media (min-width: 481px) and (max-width:1024px){
        height:100% ;
        width: 100%;
    }
    
`;

export const FixedIntro = styled.div`
    position: fixed !important;
    bottom: 50px;
    left: 120px;
    width: 30%;
    overflow-x: hidden;
    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
    }
    h2{
        font-size: 30px;
    }

    @media (max-width: 480px){
        position: relative !important;
        bottom: 0px;
        left: 20px;
        width: 90%;
        overflow-x: hidden;
        p{
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }
        h2{
            font-size: 32px;
        }
    }

    @media (min-width: 481px) and (max-width:1024px){
        position: fixed;
        bottom: 50px;
        left: 120px;
        width: 30%;
        overflow-x: hidden;
        p{
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
        }
        h2{
            font-size: 30px;
        }
    }

`;

export const ScrollMenu = styled.div`


    .menu-item{
        h4{
            font-size: 100px;
            transition: padding 0.5s linear;
          

            :hover{
                padding-left: 20px;
                font-style: italic;
            }
        }
        p{
            font-size: 13px;
            font-family: 'Montserrat', sans-serif;
        }
    }

    @media (max-width: 480px){
        .menu-item{
            padding-top: 50px;
            h4{
            font-size: 50px;
            transition: padding 0.5s linear;
          

            :hover{
                padding-left: 20px;
                font-style: italic;
            }
            }
            p{
                font-size: 13px;
                font-family: 'Montserrat', sans-serif;
            }
        }
    }

    @media (min-width: 481px) and (max-width:1024px){
        .menu-item{
            h4{
                font-size: 70px;
                transition: padding 0.5s linear;
            

                :hover{
                    padding-left: 15px;
                    font-style: italic;
                }
            }
            p{
                font-size: 13px;
                font-family: 'Montserrat', sans-serif;
            }
        }
    }
`;