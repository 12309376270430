import styled from "styled-components";
import { Chills } from '../assets';

 export const Hero = styled.div`
   height: 90vh;
   background-image: url(${Chills});
   background-position: right -60px bottom 50px;
   background-size: 600px 600px;
   background-repeat: no-repeat;
   
    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
    }

    h1.menu{
        font-size: 110px;
        transition:  padding-left 1s linear;

        :hover{
            padding-left: 20px;
        }
        
    }

    @media (max-width: 480px){

        height: 95vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: none;
        background-position: right -60px bottom 50px;
        background-size: 400px 400px;
        background-repeat: no-repeat;
        
            p{
                font-family: 'Montserrat', sans-serif;
                font-size: 13px;
            }
            
            h1.name{
                font-size:50px;
                padding-bottom: 15px;
            }

            h1.menu{
                font-size: 90px;
                transition:  padding-left 1s linear;
                /* color: #FFCC1D; */
               
                :hover{
                    padding-left: 20px;
                }
                
            }
        }
`;

