import styled from "styled-components";

export const Nav = styled.div`

   width: 100%;

        img.nav-logo{
            height: 45px;
        } 
    
    li{
        font-family: 'Montserrat', sans-serif;
    }

   
`;




