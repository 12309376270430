import React from 'react'
import { Logo } from '../assets'
import { Navbar } from '../components'
import { FixedIntro, ScrollMenu, Cont } from '../styles/workstyles'

const Work = () => {
    return (
        <section className='work-sec bg-namiri-dark' >
            <Navbar logo={Logo}></Navbar>
            <Cont className="p-4 md:p-6 bg-namiri-dark ">
                <div className="container  grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="side-intro">
                        <FixedIntro className=''>
                            <h2 className='font-Gallient text-white'>Work</h2>
                            <p className='text-white '>
                                This is a showcase of my best work in a variety of fields, from Graphic and Web Design to Product Design.
                                <br />
                                <br />
                                The world of Digital Design has grown at an extremely rapid rate over the last 10 years and my aim has been to evolve with it. I’m learning and gaining new skills every day.
                            </p>
                        </FixedIntro>
                    </div>
                    <div className="free-scroll">
                        <ScrollMenu>
                            <div className="menu-container">
                                <div className="menu-item text-white">
                                    <a href="https://womeninadr.com/" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            WADR
                                        </h4>
                                    </a>
                                    <p>
                                        Website Design & Development (Ongoing - DDD )
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://namiricreatives.com" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            NamiriCreatives
                                        </h4>
                                    </a>
                                    <p>
                                        Website Development(Ongoing)
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://lindadata.co.ke" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            Linda Data
                                        </h4>
                                    </a>
                                    <p>
                                        web design and development
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://mbajaoenterprises.com" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            Mbajao ENT
                                        </h4>
                                    </a>
                                    <p>
                                        web design and development
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://ceed.co.ke" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            Ceed Kenya.
                                        </h4>
                                    </a>
                                    <p>
                                        web design and development
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://itpa.africa" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            ITPA africa.
                                        </h4>
                                    </a>
                                    <p>
                                        web design and development
                                    </p>
                                </div>
                                <div className="menu-item text-white">
                                    <a href="https://pensglobalservices.com" target="_blank" rel="noreferrer">
                                        <h4 className="menu-link  font-Gallient">
                                            Pens Global.
                                        </h4>
                                    </a>
                                    <p>
                                        web design and development
                                    </p>
                                </div>

                                <div className="menu-item text-white">
                                    <h4 className="menu-link  font-Gallient">
                                        Reign Dental
                                    </h4>
                                    <p>
                                        Wordpress Website
                                    </p>
                                </div>

                            </div>
                        </ScrollMenu>
                    </div>
                </div>
            </Cont>
        </section>
    )
}

export default Work