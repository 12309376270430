import styled from "styled-components";


export const Hero = styled.section`
  
    .hero{
        height: 100vh;
    
    }
    .hero-text{
        
        h1{
            font-size: 150px;
            text-align: center;
            font-weight: 500;
            overflow: hidden;
           
        }

        h1 #hide{
            display: inline-block;
            transform: translateY(100%);
        }
 
        hr{
            width: 70vw;
            overflow: hidden;
        }

        /* #l0{
            transform: translateX(100%);
        } */

        #l1{
            transform: translateX(-200%);
            width: 20vw;
        }

        #l2{
            transform: translateX(-500%);
            width: 20vw;
        }
       
        .t-h{
            margin-bottom: -50px;
           
        }

        .pseudo-bg{
            height: auto;
            width: 400px;
            z-index: -99;
        }
    }

    @media (max-width: 480px){

        .hero{
            height: 60vh;
        }

        .hero-text{
        
        h1{
            font-size: 50px;
            text-align: center;
            font-weight: 400;
            overflow: hidden;
            padding: 20px 0px;
           
        }

        h1 #hide{
            display: inline-block;
            transform: translateY(100%);
        }
 
        hr{
            width: 70vw;
            overflow: hidden;
        }

        /* #l0{
            transform: translateX(100%);
        } */

        #l1{
            transform: translateX(-200%);
            width: 20vw;
            display: none;
        }

        #l2{
            transform: translateX(-500%);
            width: 20vw;
        }
       
        .t-h{
            margin-bottom: -50px;
           
        }

        .pseudo-bg{
            height: auto;
            width: 400px;
            z-index: -99;
        }
    }


    }
   
 

`;

export const Project = styled.div`

    padding: 40px;

    .project-container{
        
            position: relative;
            margin-bottom:0px;
        
      

        .project-image{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 70vw;
            }
        }
        
       
    }

    .project-details{
        margin: 0px;
    }
    p{
        color: black;
    }
    h3.tools{
        font-size:25px;
        font-weight: Bold;
    }
    .tool-imgs{
        img{
            height: 25px;
            width:auto ;
        }
    }
    .project-details{
        h4{
            font-family: 'Montserrat', sans-serif;
            font-size : 25px;
        }
       
    }

    .project-links{
        .pjo{
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
        }

        a{
           text-decoration:underline ;
        }
    }
`;