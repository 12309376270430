import styled from "styled-components";

export const AboutB = styled.div`
   padding:48px;

   h1.heading {
    padding: 12px;
    letter-spacing: 5px;
    font-size: 180px;
   }
   .About-img{
    height: 400px;
   }
   h2{
    font-size: 40px;
   }
   p{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
   }

   @media (max-width: 480px){
        padding:14px;

        h1.heading {
            padding: 12px;
            letter-spacing: 5px;
            font-size: 90px;
        }
        .About-img{
            height: auto;
            /* display: none; */
        }
        h2{
            font-size: 25px;
        }
        p{
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
        }
   }

   @media (min-width: 481px) and (max-width:1024px){

        padding:38px;

        h1.heading {
        padding: 12px;
        letter-spacing: 5px;
        font-size: 90px;
        }
        .About-img{
        height: 300px;
        width: auto;
        }
        h2{
        font-size: 30px;
        }
        p{
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        }

        /* @media (max-width: 480px){

        } */

   }
`;

export const Intro = styled.div`
        height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        p{
            font-family: 'Montserrat', sans-serif;
            font-size: 50px;
            font-weight: 600;
        }

        hr{

        width: 100%;
        height: 2px;
        background-color: #000000;
        /* border: none; */

        }

        img{
            height: 250px;
            width: 250px;
        }

        @media (max-width: 480px){
            height: 50vh;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                font-family: 'Montserrat', sans-serif;
                font-size: 20px;
            }

            img{
            height: 150px;
            width: 150px;
        }
        }
    `;


export const How = styled.section`
    
    height: 100vh;


    .font-h{
        font-size: 45px;
        font-family: 'Montserrat', sans-serif;
    }

    h2{
        font-size: 25px;
        font-weight : 600;
        font-family: 'Montserrat', sans-serif;
        padding: 15px 0px;
    }

    P{
        font-weight: 300;
    }

    hr{
        width: 100%;
        height: 2px;
        background-color: #cacaca;
        border: none;

    }


    @media (max-width: 480px){

        height: 100%;


        .font-h{
        font-size: 35px;
        font-family: 'Montserrat', sans-serif;
    }

    h2{
        font-size: 25px;
        font-weight : 600;
        font-family: 'Montserrat', sans-serif;
        padding: 15px 0px;
    }

    P{
        font-weight: 300;
    }

    hr{
        width: 100%;
        height: 2px;
        background-color: #cacaca;
        border: none;

    }

}

`;


export const End = styled.div`
    height: 100vh;
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3{
       
        font-size: 70px;
        letter-spacing: 3px;
    }

    p{
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;

    }

    @media (max-width: 480px){
        height: 100vh;
        padding: 38px;
        h3{
            font-size: 30px;
            letter-spacing: 3px;
        }

        p{
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;

        }

    }

`;