import React, { useLayoutEffect } from 'react'
import { DarkLogo, Magical, Safirisha } from '../assets'
import { Navbar } from '../components'
import { Hero, Project } from '../styles/proStyle'
import gsap from 'gsap'

const Projects = () => {

    useLayoutEffect(() => {

        let tl = gsap.timeline({ default: { ease: "SlowMo.easeOut" } })

        tl.to("#hide", { y: '0%', duration: 1.2, stagger: 0.2 })
        // tl.to("#l0", {x: '70%' , duration: 1.0, stagger:0.2} ) 
        tl.to("#l1", { x: '-100%', duration: 0.2, stagger: 0.2 })
        tl.to("#l2", { x: '200%', duration: 0.7, stagger: 0.2 })

    }, [])

    return (
        <>
            <Navbar color='bg-grey' text='text-black' border='text-black' logo={DarkLogo}></Navbar>
            <Hero className='bg-grey'>
                <div className="hero-cont">
                    <div className="hero container p-4 flex items-center justify-center">
                        <div className="hero-text ">
                            <div className="xds23">
                                <hr className='hr' id='l0' />
                                <h1 className='font-Gallient text-black t-h'>
                                    <span id="hide">
                                        Talk doesn't
                                    </span>
                                </h1>
                                <hr className='hr mt-2' id='l1' />
                                <h1 className='font-Gallient text-black b-h'>
                                    <span id='hide'>Cook Rice.</span>
                                </h1>
                                <hr className='hr' id='l2' />
                            </div>
                            <div className="xd2s2 py-5 ">
                                <p className='text-center'>
                                    トークはご飯を炊きません
                                </p>
                            </div>


                        </div>

                    </div>
                </div>
            </Hero>
            <div className="projects">
                <div className='container p-4'>
                    <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
                        <Project className='p-6 bg-gray-300'>
                            <div className="container p-0 md:p-4 m-0">
                                <div className="project-container">

                                    <div className="project-image text-center">
                                        <img src="https://res.cloudinary.com/namiri/image/upload/v1693676126/Dribbble_shot_HD_-_3_rmtrtw.png" alt="" />
                                    </div>

                                    <div className="project-details text-black ">

                                        <div className="project-name">
                                            <h4 className='text-black pt-5'>
                                                React Cart implimentations
                                            </h4>
                                        </div>
                                        <hr></hr>
                                        <div className="project-links text-black flex justify-between items-center py-3 ">
                                            <div className='pjo w-2/5 md:w-3/5'>
                                                Design & Development
                                            </div>
                                            <div className='fledx items-center justify-between'>
                                                <a href="https://cart-cfd-react.vercel.app/" target="_blank" rel="noreferrer">
                                                   Live Link
                                                </a>

                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </Project>
                        <Project className='p-6 bg-gray-300'>
                            <div className="container p-0 md:p-4 m-0">
                                <div className="project-container">

                                    <div className="project-image text-center">
                                        <img src={Safirisha} alt="" />
                                    </div>

                                    <div className="project-details text-black ">

                                        <div className="project-name">
                                            <h4 className='text-black pt-5'>
                                                Safirisha Logistics
                                            </h4>
                                        </div>
                                        <hr></hr>
                                        <div className="project-links text-black flex justify-between items-center py-3 ">
                                            <div className='pjo w-3/5  md:w-3/5 '>
                                                Design & Development
                                            </div>
                                            <div className='flex items-center justify-between'>
                                                <a href="https://safirisha-logistics.vercel.app/" target="_blank" rel="noreferrer">
                                                    Live Link
                                                </a>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Project>
                        <Project className='p-6 bg-gray-300'>
                            <div className="container p-0 md:p-4 m-0">
                                <div className="project-container">

                                    <div className="project-image text-center">
                                        <img src="https://res.cloudinary.com/namiri/image/upload/v1693676128/Dribbble_shot_HD_-_2_qa6ura.png" alt="" />
                                    </div>

                                    <div className="project-details text-black ">

                                        <div className="project-name">
                                            <h4 className='text-black pt-5'>
                                                Durusu Edu-Tech Landing Page.
                                            </h4>
                                        </div>
                                        <hr></hr>
                                        <div className="project-links text-black flex justify-between items-center py-3 ">
                                            <div className='pjo w-3/5  md:w-3/5 '>
                                                Design & Development , API testing
                                            </div>
                                            <div className='flex items-center justify-between'>
                                                <a href="https://durusu.vercel.app/" target="_blank" rel="noreferrer">
                                                    Live Link
                                                </a>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Project>

                    </div>
                </div>
            </div>



        </>
    )
}

export default Projects