import styled from "styled-components";

export const ConHero = styled.section`

    .cursor{
    height: 30px;
    width:30px; 
    border-radius: 50%;
    border:  1px solid white;
    position: absolute ;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    animation: cursorAnim .5s infinite alternate;
}

@keyframes cursorAnim {
    from{
        transform:  scale(1.5);
    }

    to {
        transform:  scale( .7);
    }
}


    height: 90vh;
    .hero-holder{
        padding-left: 150px;
    }
    h1{
        font-size: 200px;
        overflow: hidden;
    }
    h1 #hide{
            display: inline-block;
            transform: translateY(100%);
        }
    P{
        font-family: 'Montserrat', sans-serif;
    }
    p.socials{
        a{
            text-decoration: underline;
            padding: 2px;
        }
    }

    @media (max-width: 480px){
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95vh;
        .hero-holder{
            padding-left: 20px;
        }
        h1{
            font-size: 150px;
            
        }
        P{
            font-family: 'Montserrat', sans-serif;
        }
        p.socials{
            a{
                text-decoration: underline;
                padding: 2px;
            }
        }

    }

    @media (min-width: 481px) and (max-width:1024px){
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;
        .hero-holder{
            padding-left: 20px;
        }
        h1{
            font-size: 150px;
            
        }
        P{
            font-family: 'Montserrat', sans-serif;
        }
        p.socials{
            a{
                text-decoration: underline;
                padding: 2px;
            }
        }
    }
`;