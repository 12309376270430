import React from 'react'
import { Walking , Logo } from '../assets'
import { Navbar } from '../components'
import { AboutB ,Intro , End , How } from '../styles/aboutStyle'


const About = () => {
  return (
    <>
    <Navbar logo= {Logo}></Navbar>
        <AboutB className="bg-namiri-dark p-">
            <div className="md:flex items-center p-2 md:p-5 justify-center container">
                <div className="lg:w-3/5 md:w-4/5 heading-holder md:px-9 px-6 ">
                    <h1 className="font-Gallient text-5xl heading text-white font-medium">
                        About.
                    </h1>
                    <h2 className='text-white font-Gallient'> 
                    I'm Steven. A designer, Front-End Engineer and problem solver.
                    </h2>
                
                    <p className='text-white py-5 md:w-4/5'>
                    I’ve been designing messing  with computers since the day I first opened Notepad. whether it's bad code or just plain clones or startup websites. The cusp of art and technology has always fascinated me and I've never been afraid to just jump in and give it a go.
                    <br />
            
                    </p>
                </div>
                <div className="image-holder-pp p-4 ">
                    <img src={Walking} alt="Okanda waLking" className='About-img' />
                </div>
            </div>
        </AboutB>
        <Intro className='p-8 bg-white container md:w-4/5' >
            <div className="intro-holder">
                <p className='font-medium w-full md:w-4/5'>
                    Creating Timeless Brands & Helping Them Thrive in the Digital World .
                </p>
                <div className="intro-holder-2 mt-8">
                    <div className='flex justify-between items-center'>
                        <div className='w-4/5'>
                            <hr></hr>
                        </div>

                        <div>
                           <img src="/lottie.gif" alt="lottie world" srcset="" />
                        </div>

                    </div>
                </div>
                
                
            </div>
        </Intro>
       
        {/* how i can help section */}
        <How className="how-help p-8 bg-gray-300 ">
            <div className="container md:w-4/5">
                <h1 className='text-black font-h text-4xl my-8 font-bold'>
                    I can Help You with ...
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    
                    <div className="p-4">
                        <div>
                            <h3 className='text-left py-4 text-gray-500'>
                            01.    
                            </h3>
                           <hr></hr>
                        </div>
                        <h2 className='text-2xl'>
                            Design
                        </h2>
                        <p className=''>
                        I provide strong and streamlined digital designs with a proven track record in creating websites and apps. Any successful website is built on a strong foundation of company branding.
                        </p>
                        
                    </div>

                    <div className="p-4">
                        <div>
                            <h3 className='text-start py-4 text-gray-500'>
                            02.    
                            </h3>
                           <hr></hr>
                        </div>
                        <h2 className='  text-2xl'>
                            Development
                        </h2>
                        <p className=''>
                        I create scalable websites from the ground up that blend with design. Micro animations, transitions, and interaction are what I concentrate on. I use a variety of tools for content management.
                        </p>
                    </div>

                    <div className="p-4">
                        <div>
                            <h3 className='text-start py-4 text-gray-500'>
                                03.
                            </h3>
                           <hr></hr>
                        </div>
                        <h2 className=' text-2xl inline-flex items-center '>
                            <span className='text-namiri-yellow'>
                                {/* start svg */}
                                <svg width="40px" height="40px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                <title>Oval + Oval + Oval + Oval Mask</title>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Artboard" transform="translate(-468.000000, -671.000000)" fill="#000000">
                                            <g id="Oval-+-Oval-+-Oval-+-Oval-Mask" transform="translate(468.000000, 671.000000)">
                                                <path d="M99.1731586,50.0066986 C71.9402858,50.4482381 50,72.6619049 50,100 L50,100 L49.9933014,99.1731586 C49.5517619,71.9402858 27.3380951,50 0,50 L0,50 L0.826841425,49.9933014 C28.0597142,49.5517619 50,27.3380951 50,0 L50,0 L50.0066986,0.826841425 C50.4482381,28.0597142 72.6619049,50 100,50 L100,50 Z" id="Combined-Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            The Full Package
                        </h2>
                        <p className=''>
                            A complete website from concept to implementation, that's what makes me stand out. My great sense for design and my development skills enable me to create kick-ass projects.
                        </p>
                    </div>

                </div>
            </div>
        </How>

        <End className='bg-namiri-dark p-3'>
            <div className="end-cont text-center ">
                <h3 className='text-white font-Gallient font-bold' >
                    I occasionally take on  freelance opportunities.
                    </h3>
                    <p className='text-namiri-yellow '>
                    Have an exciting project where you need some help? <br />
                    Send me over a message, and <span className='text-white underline'>let's chat.</span> 
                    </p>
              
            </div>
        </End>
    </>
  )
}

export default About