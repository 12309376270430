import { Link } from 'react-router-dom'
import {Emoji} from '../assets'
import { Nav } from '../styles/navstyles'
import '../App.css'



const Navbar = (props) => {

   const bgC = props.color
   const TextColor = props.text
   const border = props.border
   const logoImage = props.logo

   


  return (
    <>
        <Nav className={`${bgC} `} id="stickynav" >
          <div className="container px-3 py-3 flex justify-between items-center -z-55">
                <a href="/" className="flex items-center  w-1/5">
                    <img src={logoImage} alt="" className='nav-logo ' /> 
                </a>
                
                <div className="menu-trigger">
                  <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500  md:hidden  focus:outline-none focus:ring-0  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" >          
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                    </svg>
                  </button>
                </div>
                <div className="hidden w-full md:block md:w-auto" id="mobile-menu">

                  <ul className={`flex  items-center flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium ${TextColor}`}>
                    <li className="wait font-NeueRegular">
                        <Link to="/about"> 
                            About Me
                        </Link>
                    </li>
                    <li className="wait font-NeueRegular">
                          <Link to='/work'> My work</Link>
                    </li>
                    <li className="wait font-NeueRegular">
                            <Link to='/project'> Mini-Projects</Link>
                    </li>
                    <div className="contact-us flex items-center justify-evenly">
                      <Link to='/connect'>
                          <button className={`flex items-center px-4 py-2 ${border} border rounded-full font-NeueRegular`}>let's Connect <img src={Emoji} className='px-2'  alt="Hi emoji" /> 
                          </button>
                      </Link>
                    </div>
                  </ul>   
                
                </div>
          </div>
         
        </Nav>
    </>
  )

  
}

Navbar.defaultProps = {
  color : "bg-namiri-dark" ,
  text : "text-white" ,
  
}

export default Navbar