import React, { useLayoutEffect, useRef  } from 'react'
import { Navbar  } from '../components'
import { ConHero } from '../styles/contactstyle'

import gsap from 'gsap'
import { Logo } from '../assets'

const Contact = () => {


    const hello = useRef()

    useLayoutEffect(()=>{

        let tl = gsap.timeline({default : { ease: "SlowMo.easeOut"}})

        tl.to(hello.current, {y: '0%' , duration: 1.2, stagger:0.2} )

    },[])

    useLayoutEffect(()=>{
        const cursor = document.querySelector('.cursor')

        document.addEventListener('mousemove', e=>{
             cursor.setAttribute("style","top :"+(e.pageY -40 )+"px; left:"+(e.pageX -40 )+"px;")   
        })

        return () => {
            document.addEventListener('mousemove', e=>{
                cursor.setAttribute("style","top :"+(e.pageY -40 )+"px; left:"+(e.pageX -40 )+"px;")   
           })
          };
    },[])

    
      


  return (
    <>

    <Navbar logo= {Logo}/>
     <ConHero className='bg-namiri-dark p-3'>
     <div class="cursor"></div>
        <div className="hero-holder container p-8">
            <h1 className='font-Gallient text-white  ' >
                <span id="hide" ref={hello}>
                    Hello
                </span>
            </h1>
            <p className='text-white text-xl'>Just Keeping it simple 😉</p>
            <div className="contact-dets py-4">
                <p className='text-white font-medium'>
                    Email: <a href="mailto:okanda@namiricreatives.com" className='underline'>okanda@namiricreatives.com</a>
                </p>
                <p className='text-white py-2 socials'>
                    On The Internet : <a href="https://www.linkedin.com/in/okandastephen/">LinkedIn </a> / <a href="http://"> Dribbble </a> / <a href="https://twitter.com/Niloticking"> Twitter </a> / <br />
                    <a href="https://github.com/king-kanda">Github</a>
                </p>
            </div>
        </div>
     </ConHero>
    </>
  )
}

export default Contact